import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
// import { useTheme } from './assets/hooks/use-theme';

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Main from "../Main/Main";
import HowItsWorks from "../HowItsWorks/HowItsWorks";
import Pricing from '../Pricing/Pricing';
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignUp/SignUp";
import ForgotPassword from "../SignIn/ForgotPassword/ForgotPassword";

import "./App.css";
import StockList from "../StockList/StockList";
import AiChecker from "../AiChecker/AiChecker";

function App() {
  // const { theme, setTheme } = useTheme()
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [apiError, setApiError] = useState("");
  const [stepPasswordReminder, setStepPasswordReminder] = useState(1);
  const navigate = useNavigate();

  //обработчик логина через гугл
  const signinWithGoogle = () => {
    setIsGoogleLoading(true);
    //запрос к api google
    console.log("Sign in with google");
    //в then перенаправить на главную и уставить состояние isLoggedIn
    setTimeout(() => navigate("/"), 500);
    //в catch поймать ошибку и установить значение apiError
    //finally
    setTimeout(() => setIsGoogleLoading(false), 500);
    setTimeout(() => {
      setApiError("");
    }, 10000);
  };

  const [login, setLogin] = useState(localStorage.getItem('login'))
  //обработчик логина
  const onSignin = (data) => {
    setIsLoading(true);
    //запрос к api, содержащий данные для логина
    console.log(data);
    localStorage.setItem('login', true)
    setLogin(true)
    //в then перенаправить на главную и уставить состояние isLoggedIn
    setTimeout(() => navigate("/stock-list"), 500);
    //в catch поймать ошибку и установить значение apiError
    //finally
    setTimeout(() => setIsLoading(false), 500);
    setTimeout(() => {
      setApiError("");
    }, 10000);
  };

  //обработчик регистрации
  const onSignup = (data) => {
    setIsLoading(true);
    //запрос к api, содержащий данные для регистрации
    console.log(data);
    //в then перенаправить на главную и уставить состояние isLoggedIn
    setTimeout(() => navigate("/"), 500);
    //в catch поймать ошибку и установить значение apiError
    setTimeout(() => {
      setApiError("There is some unknown error");
    }, 500);
    //finally
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    setTimeout(() => {
      setApiError("");
    }, 10000);
  };

  //сбрасывать востановление пароля на шаг 1 при загрузке страницы
  useEffect(() => {
    setStepPasswordReminder(1);
  }, [navigate]);

  //обработчик забытого пароля, шаг 1
  const onSubmitContacts = (data) => {
    setIsLoading(true);
    //запрос к api, содержащий номер телефона или почту
    console.log(data);
    //в then перенаправить на второй шаг восстановления пароля
    setTimeout(() => {
      setStepPasswordReminder(2);
    }, 500);
    //в catch поймать ошибку и установить значение apiError
    //finally
    setTimeout(() => setIsLoading(false), 500);
    setTimeout(() => {
      setApiError("");
    }, 10000);
  };

  //обработчик забытого пароля, шаг 2
  const onSubmitVerification = (data) => {
    setIsLoading(true);
    //запрос к api, содержащий верификационный код
    console.log(data);
    //в then перенаправить на третий шаг восстановления пароля
    setTimeout(() => {
      setStepPasswordReminder(3);
    }, 500);
    //в catch поймать ошибку и установить значение apiError
    //finally
    setTimeout(() => setIsLoading(false), 500);
    setTimeout(() => {
      setApiError("");
    }, 10000);
  };

  //повторная отправка верификационного кода
  const onResendVerification = () => {
    //запрос к api за другим кодом
    console.log("Resend");
  };

  //обработчик забытого пароля, шаг 3
  const onChangePassword = (data) => {
    setIsLoading(true);
    //запрос к api, содержащий новый пароль
    console.log(data);
    //в then перенаправить на страницу логина
    setTimeout(() => {
      navigate("/signin");
    }, 500);
    //в catch поймать ошибку и установить значение apiError
    //finally
    setTimeout(() => setIsLoading(false), 500);
    setTimeout(() => {
      setApiError("");
    }, 10000);
  };

  return (
    <div className="app">
      <Header login={login} />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/how-its-works" element={<HowItsWorks />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route
          path="/signin"
          element={
            <SignIn
              {...{
                onSignin,
                isLoading,
                signinWithGoogle,
                isGoogleLoading,
                apiError,
              }}
            />
          }
        />
        <Route
          path="/signup"
          element={<SignUp {...{ onSignup, isLoading, apiError }} />}
        />
        <Route
          path="/recovery-password"
          element={
            <ForgotPassword
              step={stepPasswordReminder}
              onResend={onResendVerification}
              {...{
                isLoading,
                apiError,
                onSubmitContacts,
                onSubmitVerification,
                onChangePassword,
              }}
            />
          }
        />
        {login ?
        <>
         <Route
            path="/stock-list"
            element={
              <StockList />
            }
          >

          </Route>
          <Route
            path="/ai-checker"
            element={
              <AiChecker />
            }
          >

          </Route>
        </>
         
          : null}

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
