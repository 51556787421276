import moment from "moment-timezone";
import { CURRENCY_MAP } from "./constants";
import { useState, useEffect } from "react";



export function parsePhone(phone) {
  let formattedInputValue = "";
  if (["7", "8", "9"].indexOf(phone[0]) > -1) {
    if (phone[0] === "9") phone = "7" + phone;

    let firstSimbols = phone[0] === "8" ? "8" : "+7";
    formattedInputValue = firstSimbols + " ";

    if (phone.length > 1) {
      formattedInputValue += "(" + phone.substring(1, 4);
    }
    if (phone.length >= 5) {
      formattedInputValue += ") " + phone.substring(4, 7);
    }
    if (phone.length >= 8) {
      formattedInputValue += "-" + phone.substring(7, 9);
    }
    if (phone.length >= 10) {
      formattedInputValue += "-" + phone.substring(9, 11);
    }
  } else {
    formattedInputValue = "+" + phone.substring(0, 16);
  }

  return formattedInputValue;
}

export function parsePromoLimit({ expire_date, usage_count }) {
  if (usage_count && expire_date) {
    const date_diff = moment(expire_date).diff(moment(), "days");

    return `${date_diff >= 3
      ? moment(expire_date).format("DD MMM YYYY")
      : moment(expire_date).fromNow()
      } · ${Number(usage_count).toLocaleString("us")}`;
  } else if (expire_date && !usage_count) {
    const date_diff = moment(expire_date).diff(moment(), "days");
    return `${date_diff >= 3
      ? moment(expire_date).format("DD MMM YYYY")
      : moment(expire_date).fromNow()
      }`;
  } else if (!expire_date && usage_count) {
    return `${Number(usage_count).toLocaleString("us")}`;
  } else {
    return "Бессрочно";
  }
}

export function StringOrNothingFormater(string) {
  return string ? string : "-";
}

export function getRandomId() {
  const ObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
  return ObjectId();
}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}



export function parseStatus(status) {
  const status_map = {
    new: 'Новый',
    in_work: 'В работе',
    paid: 'Оплачен',
    ready: 'Доставляется',
    done: 'Завершен',
    cancelled: 'Отменен',
  }

  const status_text = status_map[status] ? status_map[status] : 'Нет статуса'
  return status_text
}

export const AVAILABLE_STATUS_MAP = {
  new: [
    "in_work",
    "paid",
    "ready",
    "done",
    "cancelled",
  ],
  in_work: [
    "paid",
    "ready",
    "done",
    "cancelled",
  ],
  paid: [
    "ready",
    "done",
    "cancelled",
  ],
  ready: [
    'done',
    'cancelled',
  ],
  done: [],
  cancelled: [],
}


export function getAvailableStatuses({ config, order }) {
  if (!config || !order) return;

  // Access the specific payment type configuration
  const STATUS_CONFIG = config.payments[order.payment_type];
  const STATUS_CHANGES = STATUS_CONFIG.status_changes;

  // Initialize the map to hold available status transitions
  let availableStatusMap = {};

  // Iterate through each status change to populate the map
  Object.keys(STATUS_CHANGES).forEach(statusTo => {
    STATUS_CHANGES[statusTo].forEach(statusFrom => {
      // If the statusFrom is not yet in the map, add it with the current statusTo
      if (!availableStatusMap[statusFrom]) {
        availableStatusMap[statusFrom] = [statusTo];
      } else {
        // If the statusFrom is already in the map, append the current statusTo if not already included
        if (!availableStatusMap[statusFrom].includes(statusTo)) {
          availableStatusMap[statusFrom].push(statusTo);
        }
      }
    });

    // Ensure the statusTo is also represented in the map, even if it can't transition from other statuses
    if (!availableStatusMap[statusTo]) {
      availableStatusMap[statusTo] = [];
    }
  });

  return availableStatusMap;
}

export function parseRequestNumber(string) {
  return `№${('00000000' + string).slice(-8)}`
}


export function parsePrice(string, currency) {
  return `${CURRENCY_MAP[currency.toUpperCase()] ? CURRENCY_MAP[currency.toUpperCase()] : ''}${Number(string).toLocaleString('us')}`
}


export const areArraysDifferent = (array1, array2) => {
  // Проверка наличия каждого элемента array1 в array2
  for (let item of array1) {
    if (!array2.some(e => e._id === item._id)) {
      return true;
    }
  }

  // Проверка наличия каждого элемента array2 в array1
  for (let item of array2) {
    if (!array1.some(e => e._id === item._id)) {
      return true;
    }
  }

  // Если все элементы найдены, возвращаем false
  return false;
};

export const parseApiError = (error) => {
  if (!error || !error.statusCode) {
    return 'Неизвестная ошибка. Пожалуйста, попробуйте позже.';
  }

  switch (error.statusCode) {
    case 400:
      return 'Некорректный запрос. Проверьте введенные данные.';
    case 401:
      return 'Необходима авторизация. Пожалуйста, войдите в систему.';
    case 403:
      return 'Доступ запрещен. У вас нет прав на выполнение этого действия.';
    case 404:
      return 'Ресурс не найден. Проверьте запрос и попробуйте снова.';
    case 409:
      return 'Конфликт данных. Данные уже существуют или неверны.';
    case 429:
      return 'Слишком много запросов. Пожалуйста, подождите и повторите попытку позже.';
    case 500:
      return 'Внутренняя ошибка сервера. Мы уже работаем над её устранением.';
    case 502:
      return 'Ошибка шлюза. Проблемы с подключением к серверу.';
    case 503:
      return 'Сервис временно недоступен. Попробуйте зайти позже.';
    case 504:
      return 'Время ожидания сервера истекло. Попробуйте повторить запрос позже.';

    default:
      // Generic error messages for different ranges of status codes
      if (error.statusCode >= 400 && error.statusCode < 500) {
        return 'Произошла ошибка на стороне клиента. Пожалуйста, проверьте ваш запрос.';
      }
      if (error.statusCode >= 500) {
        return 'Произошла ошибка на стороне сервера. Мы уже работаем над её устранением.';
      }
      return 'Неизвестная ошибка. Пожалуйста, попробуйте позже.';
  }
};


export function isObject(object) {
  return object != null && typeof object === 'object';
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    console.log("Different number of keys", keys1, keys2);
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);

    if (areObjects && !deepEqual(val1, val2)) {
      console.log("Object values are not equal", key, val1, val2);
      return false;
    } else if (!areObjects && val1 !== val2) {
      console.log("Values are not equal", key, val1, val2);
      return false;
    }
  }

  return true;
}


export function transformFontStylesToApi(fontStyles) {
  const transformed = {};

  fontStyles.forEach(item => {
    transformed[item.type] = {
      weight_id: item.weight._id,
      size: parseInt(item.size, 10) // Converts "20 px" to 20
    };
  });

  return transformed;
}

export function transformColorValuesToApi(colorValues) {
  const transformed = {};

  colorValues.forEach(colorSet => {
    if (colorSet.type === 'background') {
      // For 'background', assign the color value directly
      transformed[colorSet.type] = colorSet.values[0].value;
    } else {
      // For other types, create an object with color types and values
      transformed[colorSet.type] = colorSet.values.reduce((acc, color) => {
        acc[color.type] = color.value;
        return acc;
      }, {});
    }
  });

  return transformed;
}


export function transformConstructorCardApiResponse(response) {
  // Helper function to find the title from the format value
  const findTitleByValue = (value, valuesList) => {
    const item = valuesList.find(item => item.value === value);
    return item ? item.title : "";
  };

  // Define your values lists for different types
  const aspectRatioValuesList = [
    { value: "16/10", title: "16×10" },
    { value: "1/1", title: "1×1" },
    { value: "10/16", title: "10×16" }
  ];
  const priceLocationValuesList = [
    { value: "separately", title: "Отдельно" },
    { value: "on_button", title: "На кнопке" }
  ];
  const buttonTypeValuesList = [
    { value: "button", title: "В виде кнопки" },
    { value: "text", title: "В виде текста" }
  ];
  const gridColumnValuesList = [
    { value: 3, title: "3 карточки в ряд" },
    { value: 4, title: "4 карточки в ряд" },
    { value: 5, title: "5 карточек в ряд" },
    { value: 6, title: "6 карточек в ряд" }
  ];

  const transformed = [
    {
      type: "title",
      title: "Название товара",
      value: "Название",
      is_visible: response.order.includes("item_name")
    },
    {
      type: "image",
      title: "Изображение",
      is_visible: response.order.includes("image"),
      data: [
        {
          type: "aspect_ratio",
          is_type_select: true,
          title: "Формат",
          value: {
            value: response.image.format.replace("x", "/"),
            title: findTitleByValue(response.image.format.replace("x", "/"), aspectRatioValuesList)
          },
          values_list: aspectRatioValuesList
        },
        {
          type: "radius",
          is_type_select: false,
          title: "Скругление",
          value: response.image.radius.toString()
        }
      ]
    },
    {
      type: "description",
      title: "Описание товара",
      value: "Описание",
      is_visible: response.order.includes("description")
    },
    {
      type: "price",
      title: "Блок с ценой",
      is_visible: response.order.includes("price"),
      data: [
        {
          type: "price_location",
          is_type_select: true,
          title: "Цена",
          value: {
            value: response.price.format,
            title: findTitleByValue(response.price.format === "on_button" ? "on_button" : "separately", priceLocationValuesList)
          },
          values_list: priceLocationValuesList
        },
        {
          type: "button_type",
          is_type_select: true,
          title: "Кнопка Купить",
          value: {
            value: response.price.buy_btn,
            title: findTitleByValue(response.price.buy_btn, buttonTypeValuesList)
          },
          values_list: buttonTypeValuesList
        }
      ]
    },
    {
      type: "grid_columns",
      is_type_select: true,
      title: "Размер сетки на декстопе",
      value: {
        value: response.card_in_line,
        title: findTitleByValue(response.card_in_line, gridColumnValuesList)
      },
      values_list: gridColumnValuesList
    }
  ];

  const orderedTransformed = response.order.map(orderItem => {
    switch (orderItem) {
      case "item_name":
        return transformed.find(item => item.type === "title");
      case "image":
        return transformed.find(item => item.type === "image");
      case "description":
        return transformed.find(item => item.type === "description");
      case "price":
        return transformed.find(item => item.type === "price");
      default:
        return null;
    }
  }).filter(item => item !== null);

  // Add the unchangeable grid_columns at the end
  const gridColumnsItem = transformed.find(item => item.type === "grid_columns");
  if (gridColumnsItem) {
    orderedTransformed.push(gridColumnsItem);
  }

  // Create a map for easy lookup of transformed items by type
  const transformedMap = transformed.reduce((map, item) => {
    map[item.type] = item;
    return map;
  }, {});

  // Initialize an array to hold the ordered and default items
  const orderedAndDefaultTransformed = [];

  // Process each item in the order array
  response.order.forEach(orderItem => {
    if (transformedMap[orderItem]) {
      orderedAndDefaultTransformed.push(transformedMap[orderItem]);
      delete transformedMap[orderItem]; // Remove the added item from the map
    }
  });

  // Add remaining items that were not in the order array
  for (const type in transformedMap) {
    orderedAndDefaultTransformed.push(transformedMap[type]);
  }

  return orderedAndDefaultTransformed;
}


export function transformConstructorCardToApiResponse(values) {
  const apiResponse = {
    image: {},
    price: {},
    card_in_line: 0,
    order: []
  };

  values.forEach(item => {
    switch (item.type) {
      case "title":
        if (item.is_visible) {
          apiResponse.order.push("item_name");
        }
        break;
      case "image":
        if (item.is_visible) {
          apiResponse.order.push("image");

        }
        item.data.forEach(dataItem => {
          if (dataItem.type === "aspect_ratio") {
            apiResponse.image.format = dataItem.value.value.replace("/", "x");
          } else if (dataItem.type === "radius") {
            apiResponse.image.radius = parseInt(dataItem.value);
          }
        });
        break;
      case "description":
        if (item.is_visible) {
          apiResponse.order.push("description");
        }
        break;
      case "price":
        if (item.is_visible) {
          apiResponse.order.push("price");

        }
        item.data.forEach(dataItem => {
          if (dataItem.type === "price_location") {
            apiResponse.price.format = dataItem.value.value;
          } else if (dataItem.type === "button_type") {
            apiResponse.price.buy_btn = dataItem.value.value;
          }
        });
        break;
      case "grid_columns":
        apiResponse.card_in_line = parseInt(item.value.value);
        break;

      default:
        break;
    }
  });

  return apiResponse;
}


export function getInitials(name) {
  // Trim the name to remove any leading or trailing spaces
  const trimmedName = name.trim();

  // Check if the name is in the default shop format (e.g., store-00000024)
  const shopNamePattern = /^store-(0*)([0-9]*)$/i;
  const match = trimmedName.match(shopNamePattern);
  if (match) {
    // If it matches, return 'S' followed by the number without leading zeros
    return `S${match[2]}`;
  }

  // Check if the name contains spaces
  if (trimmedName.includes(' ')) {
    // Split the name into words, filter out empty strings in case of multiple spaces, and map to get initials
    return trimmedName.split(' ')
      .filter(n => n) // Filter out any empty strings resulting from multiple spaces
      .map(n => n[0].toUpperCase()) // Map each name to its initial, converting it to uppercase
      .join(''); // Join the initials into a single string
  } else {
    // For a single name or compound name, return the first character in uppercase
    return trimmedName.charAt(0).toUpperCase();
  }
}



// Функция проверки наличия разрешения
export function hasPermission(userPermissions, requiredPermissions) {
  // Если не требуется специфических разрешений, считаем, что доступ разрешен
  if (!requiredPermissions || requiredPermissions.length === 0) {
    return true;
  }
  return requiredPermissions.some(permission => {
    const [section, action] = permission.split(":");
    return userPermissions[section] && userPermissions[section].includes(action);
  });
}


// Функция фильтрации ссылок на основе разрешений пользователя
export function filterLinksForUserRBAC(userPermissions, links) {
  return links.reduce((acc, link) => {
    // Проверяем наличие разрешений для текущей ссылки
    if (hasPermission(userPermissions, link.requiredPermissions)) {
      // Если у ссылки есть дочерние элементы, применяем фильтрацию рекурсивно
      const filteredLink = { ...link };
      if (link.children) {
        filteredLink.children = filterLinksForUserRBAC(userPermissions, link.children);
      }
      acc.push(filteredLink);
    }
    return acc;
  }, []);
}

export function getDefaultLinkForUserRBAC(links) {
  if (!links) return null
  const DEFAULT_LINK = links[0].children
    ? "/" +
    links[0].path +
    "/" +
    links[0].children[0].path
    : "/" + links[0].path


  return DEFAULT_LINK
}

export function parsePermissions(user) {
  if (!user.default_shop) return {}
  return user.default_shop.role.permissions
}

export function parseWithViewOnlyValue(isViewOnly, value) {
  return isViewOnly ? value ? value : '-' : value
}

export function createAction(name, onClick, options = {}) {
  // Generate a stable ID based on the action's name. This can be enhanced for uniqueness if necessary.
  const id = `action-${name.replace(/\s+/g, '-').toLowerCase()}`;

  return {
    id,
    name,
    onClick,
    inactive: options.inactive || false,
    isPreloaderVisible: options.isPreloaderVisible || false,
    isMainAction: options.isMainAction || false,
  };
}


export function copyToClipboard(text) {

  var textArea = document.createElement("textarea");
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Copying text command was ' + msg);
  } catch (err) {
    console.log('Oops, unable to copy');
  }

  document.body.removeChild(textArea);
}
export const getOrderWordForm = (number) => {
  const lastDigit = number % 10;
  const isException = Math.floor((number % 100) / 10) === 1;
  
  if (lastDigit === 1 && !isException) {
    return "заказ"; // Singular
  } else if (lastDigit >= 2 && lastDigit <= 4 && !isException) {
    return "заказа"; // Plural, nominative case for a small number of orders
  } else {
    return "заказов"; // Plural, genitive case for 5 or more orders, and exceptions
  }
};

// Correctly handles Russian noun forms for "корзина" based on the provided number
export const getBasketWordForm = (number) => {
  const lastDigit = number % 10;
  const isException = Math.floor((number % 100) / 10) === 1;
  
  if (lastDigit === 1 && !isException) {
    return "корзина"; // Singular
  } else if (lastDigit >= 2 && lastDigit <= 4 && !isException) {
    return "корзины"; // Plural, nominative case for a small number of baskets
  } else {
    return "корзин"; // Plural, genitive case for 5 or more baskets, and exceptions
  }
};

// Correctly handles Russian noun forms for "товар" based on the provided number
export const getProductWordForm = (number) => {
  const lastDigit = number % 10;
  const isException = Math.floor((number % 100) / 10) === 1;
  
  if (lastDigit === 1 && !isException) {
    return "товар"; // Singular
  } else if (lastDigit >= 2 && lastDigit <= 4 && !isException) {
    return "товары"; // Corrected to "товары" for 2-4
  } else {
    return "товаров"; // Plural, genitive case for 5 or more products, and exceptions
  }
};

export const PercentageChange = ({ current, previous }) => {
  // If both current and previous are 0, return 0%
  if (current === 0 && previous === 0) {
    return 0;
  }
  // If previous is 0 (and current is not, since the first if didn't return), return 100%
  if (previous === 0) {
    return 100;
  }
  // If current is 0 and previous is greater than 0, return -100%
  if (current === 0 && previous > 0) {
    return -100;
  }
  // Calculate percentage change for all other cases
  return ((current - previous) / previous) * 100;
};
