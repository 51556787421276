import moment from 'moment-timezone';
import React, { useState } from 'react'
import { Link } from 'react-router-dom';






import './Table.css'
import { parsePhone, parsePrice } from '../../../assets/utils/utils';
import { CONTROLS_TYPE, DOWNLOAD_LINK_TYPE, EMAIL_TYPE, FULL_NAME_TYPE, PHONE_TYPE, PRICE_TYPE, TEXT_TYPE, TITLE_TYPE } from '../../../assets/utils/constants';
import MiniPreloader from '../../MiniPreloader/MiniPreloader';



// headingList, list
const Table = ({ listInnerRef, headingList, list, title, nothingText, isInitTablePreloaderVisible, noting_btn }) => {

    const [linkCopied, setLinkCopied] = useState('')


    function copyToClipboard(text, type, i) {

        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = 0;
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';

            if (type === 'link') {
                setLinkCopied(i)
                setTimeout(() => {
                    setLinkCopied('')
                }, 1500);
            }
            console.log('Copying text command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }

    return (
        <div className='table'>
            <>{isInitTablePreloaderVisible ?
                <div className='table__preloader'>
<MiniPreloader />
                </div>
                :
                <>
                    {list !== undefined ?
                        <>
                            {title && <p className='table__title'>{title}</p>}
                            {list && list.length > 0 ?
                                <div className='table__table-container'>
                                    <div className='table__table-box-shadow'>
                                        <table className='table__table'>
                                            <thead className='table__thead'>
                                                <tr className='table__thead-tr'>
                                                    {headingList ? headingList.map((item, i) => (

                                                        <th className={`table__thead-th ${item.key === 'connected_via' ? 'table__thead-th_referrals-amount' : ''} ${item.type === 'ref-link-from-code-withut-copy' ? 'table__thead-th_right' : ''}`} colSpan="1" key={`table__thead-th${i}`}>
                                                            <span>
                                                                {item.name}
                                                            </span>

                                                        </th>


                                                    )) : <></>}
                                                </tr>
                                            </thead>

                                            <tbody className='table__tbody' ref={listInnerRef}>
                                                {list ? list.map((item, i) => (
                                                    <tr className='table__tbody-tr' key={`table__tbody-tr${i}`}>
                                                        {headingList.map((head_item, i2) => (
                                                            <td className={`table__tbody-td `} key={`table__tbody-tr${i}table__tbody-td${i2}`}>
                                                                {head_item.type === TITLE_TYPE ? <span className='table__text_type_title'>{item[head_item.key] ? item[head_item.key] : '-'}</span> : null}
                                                                {head_item.type === TEXT_TYPE ? item[head_item.key] ? item[head_item.key] : '-' : null}
                                                                {head_item.type === PHONE_TYPE ? <a className='table__phone' rel="noreferrer" href={`tel:+${item[head_item.key]}`} >{parsePhone(item[head_item.key])}</a> : null}
                                                                {head_item.type === EMAIL_TYPE ? <a className='table__email' rel="noreferrer" href={`mailto:${item[head_item.key]}`} >{item[head_item.key]}</a> : null}
                                                                {head_item.type === PRICE_TYPE ? parsePrice(item[head_item.key], 'RUB') : null}
                                                                {head_item.type === FULL_NAME_TYPE ?
                                                                    <div className='table__column'>
                                                                        <p className='table__first-name'>{item[head_item.key].first_name}</p>
                                                                        <p className='table__last-name'>{item[head_item.key].last_name}</p>

                                                                    </div> : null}
                                                                {
                                                                    head_item.type === DOWNLOAD_LINK_TYPE && Boolean(item[head_item.key]) ?
                                                                        <a href={item[head_item.key]} target='_blank' rel='noreferrer'>
                                                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="M8.00702 13.5H14.007" stroke="#3F77FF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M9.5 9.30001L10.7 10.5L12.7 8.50001" stroke="#3F77FF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M18.449 19.396L17.459 19.8935C17.3206 19.963 17.168 19.9995 17.0132 20C16.8583 20.0005 16.7055 19.965 16.5667 19.8964L14.9942 19.1188L13.4533 19.8929C13.3146 19.9626 13.1615 19.999 13.0062 19.9993C12.851 19.9996 12.6978 19.9637 12.5588 19.8945L11 19.1185L9.44124 19.8945C9.30224 19.9637 9.14903 19.9996 8.99376 19.9993C8.83849 19.999 8.68542 19.9626 8.54668 19.8929L7.00578 19.1188L5.4333 19.8964C5.2945 19.965 5.14166 20.0005 4.98682 20C4.83198 19.9995 4.67937 19.963 4.54102 19.8935L3.55102 19.396C3.38538 19.3128 3.24614 19.1851 3.14884 19.0273C3.05153 18.8696 3 18.6878 3 18.5025V3.49752C3 3.31215 3.05153 3.13042 3.14883 2.97264C3.24613 2.81486 3.38537 2.68723 3.551 2.604L4.541 2.10648C4.67935 2.03695 4.83196 2.0005 4.9868 2.00001C5.14164 1.99951 5.29448 2.03497 5.43328 2.10361L7.00576 2.8812L8.54666 2.10713C8.6854 2.03743 8.83847 2.00099 8.99374 2.00071C9.14901 2.00043 9.30222 2.03631 9.44122 2.10551L11 2.88151L12.5588 2.10551C12.6978 2.03631 12.851 2.00043 13.0062 2.00071C13.1615 2.00099 13.3146 2.03743 13.4533 2.10713L14.9942 2.8812L16.5667 2.10361C16.7055 2.03497 16.8583 1.99951 17.0132 2.00001C17.168 2.0005 17.3206 2.03695 17.459 2.10648L18.449 2.604C18.6146 2.68723 18.7539 2.81486 18.8512 2.97264C18.9485 3.13042 19 3.31214 19 3.49752V18.5025C19 18.6879 18.9484 18.8696 18.8512 19.0274C18.7539 19.1851 18.6146 19.3128 18.449 19.396Z" stroke="#3F77FF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                        </a>
                                                                        : null
                                                                }
                                                                {head_item.type === CONTROLS_TYPE ?
                                                                    <div className='table__controls'>
                                                                        {item[head_item.key] && item[head_item.key].length > 0 ?
                                                                            item[head_item.key].map((item2, i2) => (
                                                                                <React.Fragment key={`table__control_${i2}`}>
                                                                                    {item2.path ?
                                                                                        <Link className={`table__control table__control_type_link ${item2.inactive ? 'table__control_inactive' : ''}`} to={item2.path} onClick={item2.onClick ? item2.onClick : () => { }}>
                                                                                            {item2.name}
                                                                                        </Link>
                                                                                        : null}

                                                                                    {item2.onClick ?
                                                                                        <button className={`table__control table__control_type_button ${item2.inactive ? 'table__control_inactive' : ''}`} type='button' onClick={item2.onClick}>
                                                                                            {item2.name}
                                                                                        </button>
                                                                                        : null}
                                                                                </React.Fragment>
                                                                            ))
                                                                            : null}

                                                                    </div> : null}
                                                            </td>
                                                        ))}
                                                    </tr>))
                                                    : <></>}

                                            </tbody>
                                        </table >
                                    </div>

                                </div>
                                :
                                <>
                                    <p className='table__nothing-found'>{nothingText}</p>
                                    {noting_btn ?
                                        <Link to={noting_btn.path} className='action-btn action-btn_type_contrast product-form-placeholder__create'>{noting_btn.text}</Link>
                                        : null}
                                </>
                            }
                        </>
                        : <></>}
                </>}
            </>



        </div>
    )
};

export default Table