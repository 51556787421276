import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { partners } from '../../../assets/utils';
import phone from '../../../assets/images/main/banner-phone.png';
import avatar from '../../../assets/images/main/joe-avatar.png';
import './WelcomeBanner.css';

function WelcomeBanner() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.6 }
    }
  };

  const listContainerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2,
      }
    }
  };

  const listItemVariants = {
    hidden: { x: -10, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const circleVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: { 
      scale: 1,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        delay: 0.2 // Adjust delay as needed
      }
    }
  };

  const imageVariants = {
    hidden: { x: 200, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { 
        type: "spring",
        stiffness: 60,
        damping: 10,
        delay: 0.5 // Adjust delay to coordinate with other animations
      }
    }
  };


  return (
    <motion.section
      className="welcome"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.div className="welcome__text-block" variants={itemVariants}>
        <motion.h1 className="welcome__title">
          Welcome
          <br />
          to the 21st century of investments
        </motion.h1>
        <motion.p className="welcome__subtitle">
          Join automated investments
        </motion.p>
        {/* Enhanced animation for Link can also be implemented */}
      </motion.div>

      <motion.div className="welcome__partners-block" variants={listContainerVariants}>
        <motion.p className="welcome__partners-title" variants={itemVariants}>
          Our Partners
        </motion.p>
        <motion.ul className="welcome__partners-list" variants={listContainerVariants}>
          {partners.map((item, i) => (
            <motion.li className="welcome__item" key={i} variants={listItemVariants}>
              {item.link !== "#" ? (
                <a
                  className="welcome__partner-link"
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.icon}
                </a>
              ) : (
                <button className="welcome__partner-button" type="button">
                  {item.icon}
                </button>
              )}
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>

      <motion.div className="welcome__image-block" variants={itemVariants}>
        {/* Image and circles can have their own animations */}
        <motion.div className="welcome__back-orange-circle" variants={circleVariants} />
        <motion.div className="welcome__back-yellow-circle" variants={circleVariants} />
        <motion.img
          className="welcome__phone-image"
          src={phone}
          alt="Example look of your investments"
          variants={imageVariants}
        />

        {/* Portfolio block with staggered animations */}
        <motion.div className="welcome__portfolio-block">
          <div className="welcome__portfolio-text-block">
            <motion.p className="welcome__portfolio-title" variants={itemVariants}>
              Portfolio 1
            </motion.p>
            <div className="welcome__portfolio-numbers">
              <motion.p className="welcome__portfolio-percent" variants={itemVariants}>
                +<CountUp end={30} duration={2.75} start={0} delay={0.5} />%
              </motion.p>
              <motion.p className="welcome__portfolio-money" variants={itemVariants}>
                $<CountUp end={250} duration={2.75} start={0} delay={0.5} />
              </motion.p>
            </div>
          </div>
          <div className="welcome__portfolio-graph">
            <svg
              className="welcome__graph-icon"
              width="138"
              height="34"
              viewBox="0 0 138 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="welcome__graph-stroke"
                d="M1 33L32.1537 21.2945C32.3772 21.2105 32.6233 21.2093 32.8476 21.291L64.7301 32.9017C64.9065 32.9659 65.0973 32.9792 65.2809 32.9401L101.805 25.1511C101.934 25.1237 102.056 25.0712 102.164 24.9966L137 1"
                stroke="#37AD2D"
                strokeWidth="1.33034"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </motion.div>

        <div className="welcome__history-block">
          <motion.p className="welcome__history-title" variants={itemVariants}> History of the action</motion.p>
          <motion.ul className="welcome__history-graph" variants={listContainerVariants}>
            <motion.li className="welcome__month-graph" variants={listItemVariants}>
              <div className="welcome__graph welcome__graph_type_nov" />
              <motion.p  className="welcome__month" variants={itemVariants}>Nov</motion.p>
            </motion.li>
            <motion.li className="welcome__month-graph" variants={listItemVariants}>
              <div className="welcome__graph welcome__graph_type_dec" />
              <motion.p  className="welcome__month" variants={itemVariants}>Dec</motion.p>
            </motion.li>
            <motion.li className="welcome__month-graph" variants={listItemVariants}>
              <div className="welcome__graph welcome__graph_type_jan" />
              <motion.p  className="welcome__month" variants={itemVariants}>Jan</motion.p>
            </motion.li>
            <motion.li className="welcome__month-graph" variants={listItemVariants}>
              <div className="welcome__graph welcome__graph_type_feb" />
              <motion.p  className="welcome__month" variants={itemVariants}>Feb</motion.p>
            </motion.li>
            <motion.li className="welcome__month-graph" variants={listItemVariants}>
              <div className="welcome__graph welcome__graph_type_mar" />
              <motion.p  className="welcome__month" variants={itemVariants}>Mar</motion.p>
            </motion.li>
          </motion.ul>
        </div>

        <motion.div className="welcome__profile-block" variants={itemVariants}>
          <motion.div className="welcome__info-block" variants={listContainerVariants}>
            <motion.div className="welcome__avatar-box" variants={listItemVariants}>
              <img
                className="welcome__avatar"
                src={avatar}
                alt="Example avatar"
              />
            </motion.div>
            <motion.div className="welcome__profile-text" variants={listItemVariants}>
              <p className="welcome__profile-name">Joe Smith</p>
              <p className="welcome__profile-date">Joined in May 12, 2021</p>
              <div className="welcome__profile-money-box">
                <svg
                  className="welcome__money-icon"
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="welcome__money-icon-stroke"
                    d="M2.72727 2.3787C2.72727 1.34112 3.5684 0.5 4.60597 0.5H13.1213C14.1589 0.5 15 1.34112 15 2.3787V7.6213C15 8.65888 14.1589 9.5 13.1213 9.5H4.60597C3.56839 9.5 2.72727 8.65888 2.72727 7.6213V2.3787ZM8.86364 2.75C9.40613 2.75 9.92639 2.98705 10.31 3.40901C10.6936 3.83097 10.9091 4.40326 10.9091 5C10.9091 5.59674 10.6936 6.16903 10.31 6.59099C9.92639 7.01295 9.40613 7.25 8.86364 7.25C8.32115 7.25 7.80088 7.01295 7.41728 6.59099C7.03368 6.16903 6.81818 5.59674 6.81818 5C6.81818 4.40326 7.03368 3.83097 7.41728 3.40901C7.80088 2.98705 8.32115 2.75 8.86364 2.75ZM5.45455 2C5.45455 2.39782 5.31088 2.77936 5.05515 3.06066C4.79941 3.34196 4.45257 3.5 4.09091 3.5V6.5C4.45257 6.5 4.79941 6.65804 5.05515 6.93934C5.31088 7.22064 5.45455 7.60218 5.45455 8H12.2727C12.2727 7.60218 12.4164 7.22064 12.6721 6.93934C12.9279 6.65804 13.2747 6.5 13.6364 6.5V3.5C13.2747 3.5 12.9279 3.34196 12.6721 3.06066C12.4164 2.77936 12.2727 2.39782 12.2727 2H5.45455ZM0 3.5H1.36364V9.1213C1.36364 10.1589 2.20476 11 3.24234 11H12.2727V12.5H1.8787C0.841121 12.5 0 11.6589 0 10.6213V3.5Z"
                    fill="#69C760"
                  />
                </svg>
                <motion.p className="welcome__profile-money" variants={itemVariants}>$ <CountUp end={12237} duration={2.75} start={0} delay={0.5} /></motion.p>
              </div>
            </motion.div>
          </motion.div>
          <div className="welcome__profile-button">
            <p className="welcome__button-text">New portfolio</p>
          </div>
        </motion.div>
        {/* Implement similar staggering for the history and profile blocks for a cohesive effect */}
      </motion.div>
    </motion.section>
  );
}

export default WelcomeBanner;