import { useState } from 'react';
import './AiChecker.css';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import { parsePrice } from '../../assets/utils/utils';
import moment from 'moment-timezone';
import aiApi from '../../assets/api/AIApi';
import { MAIN_URL } from '../../assets/utils/constants';

function AiChecker() {
    const [values, setVelues] = useState({
        ticker: '',
    })
    const [preloaders, setPreloaders] = useState({
        ticker: '',
    })
    const [error, setError] = useState('')
    const [data, setData] = useState(null)
    function handleChange(e) {
        const input = e.target;
        let value = input.value;
        const name = input.name

        switch (name) {

            case "ticker":
                setData(null)
                setVelues(prevValue => ({
                    ...prevValue,
                    [name]: value.toUpperCase().replace(/[^A-Z]/g, ''),
                }))
                break;

            default:
                setVelues(prevValue => ({
                    ...prevValue,
                    [name]: value,
                }))
                break;
        }
    }


    function handleSubmit() {
        if (preloaders.ticker) return
        setPreloaders(prevValue => ({
            ...prevValue,
            ticker: true,
        }))
        // setData({
        //     "last_price": 160.2,
        //     "predicted_values": [
        //         {
        //             "date": "2024-05-04T00:00:00",
        //             "value": 160.2
        //         }
        //     ],
        //     "recommended_action": "HOLD",
        //     "file": {
        //         "key": "stocks_prediction.json",
        //         "url": "http://localhost:8000/stocks_prediction"
        //     }
        // })
        aiApi.getStockInfo({ ticker: values.ticker })
            .then((res) => {
                console.log(res)
                setData(res)
            })
            .catch((err) => {
                setError(err)
                console.log(err)
            })
            .finally(() => {
                setPreloaders(prevValue => ({
                    ...prevValue,
                    ticker: false,
                }))
            })

    }
    return (
        <div className='ai-checker'>
            <div className='ai-checker__container'>
                {!data ?
                    <>
                        <p className='ai-checker__title'>AI Checker</p>
                        <p className='ai-checker__subtitle'>Here you can check any available stocks using artificial intelligence and get a price forecast for a short period</p>
                        <label className="auth__label auth__label_ai-checker" htmlFor="ticker">
                            Ticker
                            {/* <span className="auth__error" id="email-error">
                        {errors.email || ""}
                    </span> */}
                            <input
                                className={`auth__input auth__input_type_email`}
                                id="ticker"
                                name="ticker"
                                type="text"
                                placeholder="Ticker"
                                autoComplete="on"
                                value={values.ticker || ""}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <button className={`ai-checker__submit ${!values.ticker ? 'ai-checker__submit_disabled' : ''}`} onClick={handleSubmit} type='button'>
                            {preloaders.ticker ? <MiniPreloader isLinkColor={true} /> : 'Check'}
                        </button>
                        {error ?
                            <p className={error.statusCode === 404 ? 'ai-checker__error' : 'ai-checker__hint'}>{error.statusCode === 404 ? 'This ticker was not found' : 'We are processing the request for this ticker. Please try to request it again in 5 minutes'}</p>
                            : null}
                    </>
                    : null}

                {data ?
                    <>
                        <button className='ai-checker__data-return' type='button' onClick={() => {
                            setData(null)
                            setVelues(prevValue => ({
                                ...prevValue,
                                ticker: '',
                            }))
                            setPreloaders(prevValue => ({
                                ...prevValue,
                                ticker: false,
                            }))
                            setError('')
                        }}>
                            {`<`} Return
                        </button>
                        <div className='ai-checker__data'>
                            <p className='ai-checker__data-title'>
                                Info for <a className='ai-checker__data-title_ticker' href={`https://finance.yahoo.com/quote/${values.ticker}`} target='_blank' rel='noreferrer'>${values.ticker}</a>
                            </p>
                            <div className='ai-checker__summary'>
                                <div className='ai-checker__summary-item'>
                                    <p className='ai-checker__summary-item-title'>Current Price</p>
                                    <p className='ai-checker__summary-item-value'>{parsePrice(data.last_price, 'USD')}</p>
                                </div>
                                <div className='ai-checker__summary-item'>
                                    <p className='ai-checker__summary-item-title'>Predicted Price</p>
                                    {data.predicted_value.map((item, i) => (
                                        <p className='ai-checker__summary-item-value' key={`ai-checker__summary-item-value-predict-${i}`}>{parsePrice(item.value, 'USD')} {moment(item.date + '+00:00').format('DD.MM')}</p>

                                    ))}
                                </div>
                                {/* <div className='ai-checker__summary-item'>
                                    <p className='ai-checker__summary-item-title'>Recommended Action</p>
                                    <p className='ai-checker__summary-item-value'>{data.recommended_action}</p>
                                </div> */}
                            </div>
                            <img className='ai-checker__graph' src={`${data.file.url}`} alt=''></img>
                        </div>
                    </>

                    : null}
            </div>
        </div>
    );
}

export default AiChecker