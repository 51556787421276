import {  MAIN_URL } from "../utils/constants";

class AIApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }



  getStockInfo({ ticker }) {
    let params = {};
    if (ticker) params.ticker = ticker;

    return fetch(
      `${MAIN_URL}/stocks/predict?` +
      new URLSearchParams(params),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }
}

const aiApi = new AIApi({
  baseUrl: MAIN_URL,
});

export default aiApi;
