import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from "react-router-dom";
import girl from "../../../assets/images/how-its-works/girl.png";
import graph from "../../../assets/images/how-its-works/graph.png";
import "./Banner.css";

function Banner() {
  // Animation controls for each section
  const controlsText = useAnimation();
  const controlsGirlImage = useAnimation();
  const controlsGraphImage = useAnimation();
  const controlsSvg = useAnimation();

  // Viewport visibility hooks for each section
  const [refText, inViewText] = useInView({ triggerOnce: true, rootMargin: "-200px 0px" });
  const [refGirlImage, inViewGirlImage] = useInView({ triggerOnce: true, rootMargin: "-200px 0px" });
  const [refGraphImage, inViewGraphImage] = useInView({ triggerOnce: true, rootMargin: "-200px 0px" });
  const [refSvg, inViewSvg] = useInView({ triggerOnce: true, rootMargin: "-200px 0px" });

  useEffect(() => {
    if (inViewGirlImage) {
      controlsText.start("visible");
      controlsGirlImage.start("visible");
      controlsGraphImage.start("visible");
      controlsSvg.start("visible");
    }
  }, [controlsText, inViewText, controlsGirlImage, inViewGirlImage, controlsGraphImage, inViewGraphImage, controlsSvg, inViewSvg]);

  // Custom animation variants for each section
  const textVariants = {
    visible: { opacity: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0 },
  };

  const girlImageVariants = {
    visible: { x: 0, opacity: 1, transition: { type: "spring", stiffness: 120 } },
    hidden: { x: -50, opacity: 0 },
  };

  const graphImageVariants = {
    visible: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.5 } },
    hidden: { y: 50, opacity: 0 },
  };

  const svgVariants = {
    visible: { rotate: 0, scale: 1, opacity: 1, transition: { delay: 0.4, duration: 0.7, type: "spring", stiffness: 100 } },
    hidden: { rotate: 90, scale: 0, opacity: 0 },
  };

  return (
    <div className="banner">
      <motion.div className="banner__text-block" ref={refText} initial="hidden" animate={controlsText} variants={textVariants}>
        <h2 className="banner__title">AI Portfolio Builder</h2>
        <p className="banner__subtitle">
          AI will allow you to build a portfolio safely and profitably
        </p>
        {/* Link Button */}
      </motion.div>

      <div className="banner__image-block">
        <div className="banner__back-circle" />
        <motion.img className="banner__girl-image" src={girl} alt="Greeting girl" ref={refGirlImage} initial="hidden" animate={controlsGirlImage} variants={girlImageVariants} />
        <motion.div className="banner__greeting-box" initial="hidden" animate={controlsSvg} variants={svgVariants}>
          <svg className="banner__triangle-icon" width="58" height="41" viewBox="0 0 58 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M57.6896 0.804124L0.547673 7.53437L16.9566 40.1018L57.6896 0.804124Z" fill="white" />
          </svg>
          <p className="banner__greeting-text">
            Hi!<br />I'm here to <span className="banner__greeting-text-semibold">explain</span> to you how our service works
          </p>
        </motion.div>
        <motion.div className="banner__graph-box" ref={refGraphImage} initial="hidden" animate={controlsGraphImage} variants={graphImageVariants}>
          <p className="banner__graph-title">Historical PnL</p>
          <img className="banner__graph-image" src={graph} alt="Example graph" />
        </motion.div>
      </div>
    </div>
  );
}

export default Banner;
