import Banner from "./Banner/Banner";
import Steps from "./Steps/Steps";
import Questions from "./Questions/Questions";
import "./HowItsWorks.css";

function HowItsWorks() {
  return (
    <section className="how-works">
      <Banner />
      <Steps />
      <Questions />
    </section>
  );
}

export default HowItsWorks;
