import WelcomeBanner from "./WelcomeBanner/WelcomeBanner";
import Promo from "./Promo/Promo";
import Advantages from "./Advantages/Advantages";
import Team from "./Team/Team";
import HowSystemWorks from "./HowSystemWorks/HowSystemWorks";
import Start from "./Start/Start";
import "./Main.css";

function Main() {
  return (
    <main className="main">
      <WelcomeBanner />
      <Promo />
      <Advantages />
      {/* <Team /> */}
      <HowSystemWorks />
      <Start />
    </main>
  );
}

export default Main;
