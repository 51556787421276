import { useEffect, useState } from 'react';
import { STOCK_LIST, TEXT_TYPE, TITLE_TYPE } from '../../assets/utils/constants';
import './StockList.css';
import Table from './Table/Table';
import { parsePrice } from '../../assets/utils/utils';
import { Link } from 'react-router-dom';

function StockList() {

    const table_heading_list = [
        {
            name: 'Ticker',
            key: 'ticker',
            type: TITLE_TYPE,
        },
        {
            name: 'Reccomended position',
            key: 'r_pos',
            type: TEXT_TYPE,
        },
        {
            name: 'Price status',
            key: 'price_status',
            type: TEXT_TYPE,
        },
        {
            name: 'Current price',
            key: 'cur_price',
            type: TEXT_TYPE,
        },


        {
            name: 'Profit',
            key: 'profit',
            type: TEXT_TYPE,
        },
        {
            name: 'Prediction',
            key: 'prediction',
            type: TEXT_TYPE,
        },
        {
            name: 'Probability',
            key: 'probability',
            type: TEXT_TYPE,
        },
        {
            name: 'Out minus',
            key: 'out_minus',
            type: TEXT_TYPE,
        },
        {
            name: 'Out plus',
            key: 'out_plus',
            type: TEXT_TYPE,
        },
        {
            name: 'Out date',
            key: 'out_date',
            type: TEXT_TYPE,
        },
        {
            name: 'Depends on',
            key: 'depends_on',
            type: TEXT_TYPE,
        },
    ]

    const [isPreloaderVisible, setPreloaderVisible] = useState(true)
    const [stocks, setStocks] = useState(null)
    function mergeStockData(purchases, fetchedData) {
        return purchases.map(purchase => {
            const fetchedInfo = fetchedData.find(stock => stock.symbol === purchase.ticker);
            if (fetchedInfo) {
                return {
                    ...purchase,
                    ...fetchedInfo,
                };
            }
            return purchase; // Return the purchase as is if no matching fetched info
        });
    }

    // Function to save data to localStorage
    function saveDataToLocal(data) {
        const dataToSave = {
            timestamp: new Date().getTime(),
            data: data,
        };
        localStorage.setItem('stockData', JSON.stringify(dataToSave));
    }

    // Function to check if saved data is still valid (within 10 minutes)
    function isDataValid() {
        const saved = localStorage.getItem('stockData');
        if (!saved) return false;
        const savedData = JSON.parse(saved);
        const now = new Date().getTime();
        // Check if the saved data is older than 10 minutes
        return now - savedData.timestamp < 10 * 60 * 1000;
    }


    useEffect(() => {
        async function getStockPrices(symbols) {
            setPreloaderVisible(true);

            // Check if valid data is saved locally
            if (isDataValid()) {
                const savedData = JSON.parse(localStorage.getItem('stockData')).data;
                setStocks(mergeStockData(STOCK_LIST, savedData));
                setPreloaderVisible(false);
                return;
            }

            const endpoint = `https://financialmodelingprep.com/api/v3/quote/${symbols.join(',')}?apikey=1e0f63d0e2460544f44377169987d85b`;
            try {
                const response = await fetch(endpoint);
                const data = await response.json();
                saveDataToLocal(data); // Save fetched data along with the current timestamp
                setStocks(mergeStockData(STOCK_LIST, data));
                setPreloaderVisible(false);
            } catch (error) {
                console.error('Error fetching stock prices:', error);
            }
        }

        getStockPrices(STOCK_LIST.map(item => item.ticker));

    }, [STOCK_LIST])

    function calculatePriceDifferencePercent(currentPrice, purchasePrice) {
        console.log(currentPrice, purchasePrice)
        // Ensure the prices are numbers and not strings
        currentPrice = Number(currentPrice);
        purchasePrice = Number(purchasePrice);

        // Calculate the percentage difference
        const differencePercent = ((currentPrice - purchasePrice) / purchasePrice) * 100;

        // Return the result, rounded to two decimal places for readability
        return differencePercent.toFixed(2);
    }

    console.log(stocks)

    function calculatePortfolioMetrics(portfolio) {
        let totalPortfolioPrice = 0;
        let totalPortfolioProfit = 0;
        let initialPortfolioCost = 0;

        if (!portfolio) return {
            Initial_Portfolio_Cost: initialPortfolioCost.toFixed(2),
            Total_Portfolio_Price: totalPortfolioPrice.toFixed(2),
            Total_Portfolio_Profit: totalPortfolioProfit.toFixed(2)
        }
        portfolio.forEach(stock => {
            const currentTotalPrice =  stock.price * stock.amount;
            const purchaseTotalPrice = stock.purchase_price * stock.amount;
            const profit = stock.IsShort ? purchaseTotalPrice - currentTotalPrice : currentTotalPrice - purchaseTotalPrice;

            totalPortfolioPrice += currentTotalPrice;
            totalPortfolioProfit += profit;
            initialPortfolioCost += purchaseTotalPrice;
        });

        return {
            Initial_Portfolio_Cost: initialPortfolioCost.toFixed(2),
            Total_Portfolio_Price: totalPortfolioPrice.toFixed(2),
            Total_Portfolio_Profit: totalPortfolioProfit.toFixed(2)
        };
    }


    const { Initial_Portfolio_Cost, Total_Portfolio_Price, Total_Portfolio_Profit } = calculatePortfolioMetrics(stocks);
    console.log(`Initial Portfolio Cost: $${Initial_Portfolio_Cost}`);
    console.log(`Total Portfolio Price: $${Total_Portfolio_Price}`);
    console.log(`Total Portfolio Profit: $${Total_Portfolio_Profit}`);


    return (
        <div className='stock-list'>
            <div className='portfolio__summary'>
                <div className='portfolio__summary-item'>
                    <p className='portfolio__summary-item-title'>Initial Cost</p>
                    <p className='portfolio__summary-item-value'>{parsePrice(25938, 'USD')}</p>
                </div>
                <div className='portfolio__summary-item'>
                    <p className='portfolio__summary-item-title'>Total Cost</p>
                    <p className='portfolio__summary-item-value'>{parsePrice((25938 + Number(Total_Portfolio_Profit)), 'USD')}</p>
                </div>
                <div className='portfolio__summary-item'>
                    <p className='portfolio__summary-item-title'>Total Profit</p>
                    <p className='portfolio__summary-item-value'>{parsePrice(Total_Portfolio_Profit, 'USD')} {`${calculatePriceDifferencePercent(25938, 25938 + Total_Portfolio_Profit ) >= 0 ? '+' : '-'}${Math.abs(calculatePriceDifferencePercent(25938, 25938 - Total_Portfolio_Profit))}%`}</p>
                </div>
            </div>
            <Link
                className='stock-list__link'
                to={'/ai-checker'}>
                AI checker {'>'}
            </Link>
            <div className='stock-list__container'>
                <div className='stock-list__list-container'>

                    <Table
                        headingList={table_heading_list}


                        list={stocks && stocks.length > 0 ?
                            stocks.map((item, i) => {
                                const priceDiffPercent = calculatePriceDifferencePercent(item.price, item.purchase_price)
                                return {
                                    ...item,
                                    r_pos: item.IsShort ? 'Short' : 'Hold',
                                    price_status: item.IsShort ? 'Overpriced' : priceDiffPercent <= 0 ? 'Undervalued' : priceDiffPercent > 0 && priceDiffPercent < 10 ? 'Normal' : 'Good',
                                    cur_price: parsePrice(item.price, 'USD'),
                                    t_price: parsePrice(item.price + (item.price * 0.10), 'USD'),
                                    profit: `${priceDiffPercent >= 0 ? item.IsShort ? '-' : '+' : item.IsShort ? '+' : '-'}${Math.abs(priceDiffPercent)}%`,
                                    prediction: item.IsShort ? 'DOWN' : 'UP',
                                    probability: ((i % 2 > 1 ? 98.61 : 95.59) - Math.abs(priceDiffPercent)).toFixed(2) + '%',
                                    out_minus: item.OutMinus ? parsePrice(item.OutMinus, 'USD') : '',
                                    out_plus: item.OutPlus ? parsePrice(item.OutPlus, 'USD') : '',
                                    out_date: item.OutDate,
                                    depends_on: item.DependOn,
                                }
                            }) : null}
                        isInitTablePreloaderVisible={isPreloaderVisible}
                    />
                </div>


            </div>
        </div>
    );
}

export default StockList