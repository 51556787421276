export const STOCK_LIST = [
    // { purchase_date: "20.02.2024", ticker: "NVDA", amount: 1, purchase_price: 687.49 },
    // { purchase_date: "27.02.2024", ticker: "MSFT", amount: 1, purchase_price: 406.36 },
    // { purchase_date: "27.02.2024", ticker: "TSM", amount: 2, purchase_price: 129.385 },
    // { purchase_date: "27.02.2024", ticker: "WBD", amount: 25, purchase_price: 8.715 },
    // { purchase_date: "07.03.2024", ticker: "XLE", amount: 4, purchase_price: 87.94 },
    // { purchase_date: "20.03.2024", ticker: "AMD", amount: 8, purchase_price: 181.04 },
    // { purchase_date: "20.03.2024", ticker: "MSFT", amount: 2, purchase_price: 421.25 },
    // { purchase_date: "20.03.2024", ticker: "XLE", amount: 20, purchase_price: 91.65 },
    // { purchase_date: "27.03.2024", ticker: "BABA", amount: 5, purchase_price: 70.84 },
    // { purchase_date: "27.03.2024", ticker: "BIDU", amount: 3, purchase_price: 102.69 },
    // { purchase_date: "27.03.2024", ticker: "KC", amount: 110, purchase_price: 2.95 },
    // { purchase_date: "27.03.2024", ticker: "TSM", amount: 3, purchase_price: 138.8 },
    // { purchase_date: "01.04.2024", ticker: "BA", amount: 4, purchase_price: 189.715 },
    // { purchase_date: "01.04.2024", ticker: "MU", amount: 7, purchase_price: 123.6 },
    // { purchase_date: "01.04.2024", ticker: "TSM", amount: 3, purchase_price: 140.74 },
    // { purchase_date: "01.04.2024", ticker: "V", amount: 5, purchase_price: 277.6 },

    // { ticker: "NVDA", amount: 6, purchase_price: 794.54, OutMinus: 750, OutPlus: '', OutDate: '06.2024', DependOn: '#OPENAI' },
    // { ticker: "MSFT", amount: 3, purchase_price: 416.29, OutMinus: 360, OutPlus: 600, OutDate: '', DependOn: '' },
    // { ticker: "TSM", amount: 8, purchase_price: 137.17, OutMinus: 85, OutPlus: 340, OutDate: '', DependOn: '#BERKSHIRE,#EARTHQUAKE' },
    // { ticker: "WBD", amount: 25, purchase_price: 8.715, OutMinus: 5.5, OutPlus: 28, OutDate: '', DependOn: '$PARA' },
    // { ticker: "XLE", amount: 24, purchase_price: 91.03, OutMinus: '', OutPlus: 160, OutDate: '', DependOn: '#UKR,#ISRL' },
    // { ticker: "AMD", amount: 8, purchase_price: 181.04, OutMinus: 140, OutPlus: '', OutDate: '05.2024', DependOn: '$NVDA' },
    // { ticker: "BABA", amount: 5, purchase_price: 70.84, OutMinus: 63, OutPlus: 125, OutDate: '', DependOn: '' },
    // { ticker: "BIDU", amount: 3, purchase_price: 102.69, OutMinus: 87, OutPlus: 140, OutDate: '', DependOn: '' },
    // { ticker: "KC", amount: 110, purchase_price: 2.95, OutMinus: '', OutPlus: 40, OutDate: '08.2024', DependOn: '' },
    // { ticker: "BA", amount: 4, purchase_price: 189.715, OutMinus: '', OutPlus: '', OutDate: '', DependOn: '' },
    // { ticker: "MU", amount: 7, purchase_price: 123.6, OutMinus: '', OutPlus: '', OutDate: '', DependOn: '' },
    // { ticker: "V", amount: 5, purchase_price: 277.6, OutMinus: '', OutPlus: '', OutDate: '', DependOn: '' },
    { ticker: "ADBE", amount: 2, purchase_price: 477.34, OutMinus: 443.72, OutPlus: 572.48, OutDate: '', DependOn: '#GOOGLE,#AI' },
    { ticker: "AVGO", amount: 1, purchase_price: 1372.29, OutMinus: 1471.28, OutPlus: 1100.04, OutDate: '', DependOn: '#MONTHLY', IsShort: true, },
    { ticker: "DLTR", amount: 3, purchase_price: 119.66, OutMinus: 110.49, OutPlus: 142.57, OutDate: '', DependOn: '#FUNDAMENTALS' },
    { ticker: "MNST", amount: 39, purchase_price: 54.22, OutMinus: 48.56, OutPlus: 62.66, OutDate: '', DependOn: '#COLA' },

    { ticker: "DDOG", amount: 4, purchase_price: 119.31, OutMinus: 110.95, OutPlus: 143.17, OutDate: '', DependOn: '#JPMORGAN' },
    { ticker: "GOOG", amount: 3, purchase_price: 174.63, OutMinus: 162.40, OutPlus: 209.55, OutDate: '', DependOn: '#DIVIDEND' },
    // { ticker: "INTC", amount: 17, purchase_price: 31.24, OutMinus: 29.05, OutPlus: 37.48, OutDate: '', DependOn: '' },
    { ticker: "LRCX", amount: 3, purchase_price: 933.63, OutMinus: 868.27, OutPlus: 1120.35, OutDate: '', DependOn: '#JPMORGAN' },
    { ticker: "META", amount: 4, purchase_price: 474.41, OutMinus: 441.20, OutPlus: 569.29, OutDate: '', DependOn: '#ANNUAL' },
    // { ticker: "PAWN", amount: 2, purchase_price: 313.95, OutMinus: 291.97, OutPlus: 376.74, OutDate: '', DependOn: '#PALO_ALTO' },
    // { ticker: "PDD", amount: 3, purchase_price: 142.04, OutMinus: 132.09, OutPlus: 170.44, OutDate: '', DependOn: '' },
    { ticker: "PEP", amount: 9, purchase_price: 182.19, OutMinus: 169.43, OutPlus: 218.62, OutDate: '', DependOn: '#COLA' },
    { ticker: "SNPS", amount: 3, purchase_price: 574.23, OutMinus: 534.03, OutPlus: 689.07, OutDate: '', DependOn: '#SINOPSIS' },
    
    { ticker: "ASML", amount: 1, purchase_price: 927.84, OutMinus: 862.89, OutPlus: 1113.40, OutDate: '', DependOn: '' },
    { ticker: "CHTR", amount: 4, purchase_price: 273.40, OutMinus: 293.97, OutPlus: 227.83, OutDate: '', DependOn: '#JPMORGAN', IsShort: true,  },
    { ticker: "KLAC", amount: 2, purchase_price: 748.86, OutMinus: 696.43, OutPlus: 898.63, OutDate: '', DependOn: '#BANK_OF_AMERICA' },
    { ticker: "LIN", amount: 3, purchase_price: 433.07, OutMinus: 402.75, OutPlus: 519.68, OutDate: '', DependOn: '#DIVIDEND' },
];

// export const TXS_LIST = [
//     { utcDatetime: "2022-01-01T00:00:00", ticker: "ADBE", amount: 2, price: 477.34, isBuy: true },
// ];

export const MAIN_URL = 'https://ai-parser.cabatest.ru'



export const TEXT_TYPE = 'text'
export const TITLE_TYPE = 'title'
export const PRICE_TYPE = 'price'
export const PHONE_TYPE = 'phone'
export const EMAIL_TYPE = 'email'
export const FULL_NAME_TYPE = 'full_name'
export const CONTROLS_TYPE = 'controls'
export const SUBMIT_TYPE_TYPE = 'submit_type'
export const PAYMENT_TYPE_TYPE = 'payment_type'
export const REQUEST_NUMBER_TYPE = 'request_number'
export const DATE_WITH_FORMAT_TYPE = 'date_with_format'
export const NUMBER_STRING_TYPE = 'number_string'
export const STATUS_TYPE = 'status'
export const ICON_TYPE = 'icon'
export const CARD_ICON_TYPE = 'card-icon'
export const DOWNLOAD_LINK_TYPE = 'download-link'




export const CURRENCY_MAP = {
    'USD': '$',  // United States Dollar
    'EUR': '€',  // Euro
    'JPY': '¥',  // Japanese Yen
    'GBP': '£',  // British Pound
    'AUD': 'A$', // Australian Dollar
    'CAD': 'C$', // Canadian Dollar
    'CHF': 'CHF', // Swiss Franc
    'CNY': '¥',  // Chinese Yuan
    'SEK': 'kr',  // Swedish Krona
    'NZD': 'NZ$', // New Zealand Dollar
    'MXN': '$',  // Mexican Peso
    'SGD': 'S$', // Singapore Dollar
    'HKD': 'HK$', // Hong Kong Dollar
    'NOK': 'kr',  // Norwegian Krone
    'KRW': '₩',  // South Korean Won
    'TRY': '₺',  // Turkish Lira
    'INR': '₹',  // Indian Rupee
    'RUB': '₽',  // Russian Ruble
    'BRL': 'R$', // Brazilian Real
    'ZAR': 'R',  // South African Rand
};
